@charset "UTF-8";
@import "https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700;900&display=swap";
body {
  margin: 0;
  padding: 0;
  font-family: "Noto Serif JP" !important;
  background: #e7f3fb;
  font-size: 14px;
  color: #1b3751;
  line-height: normal;
  font-weight: 400;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.timer {
  display: block;
}

.arrow {
  background: url(../img/dropdown_arrow.svg) no-repeat center;
  height: 8px;
  width: 14px;
  transform: rotate(180deg);
  float: right;
  margin-top: 5px;
  display: none;
}

.collapsed .arrow {
  transform: rotate(0deg);
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

input[type=button i].datetimepicker {
  text-align: left;
}

.custom-tooltip {
  position: relative;
}
.custom-tooltip:hover::after {
  content: attr(message);
  position: absolute;
  bottom: -28px;
  left: 0px;
  width: 100%;
}

a {
  text-decoration: none;
}
a:link {
  outline: none;
  text-decoration: none;
}
a:active {
  outline: none;
  text-decoration: none;
}
a:hover {
  outline: none;
  text-decoration: none;
}
a:focus {
  outline: none;
}

button:focus {
  outline: none;
}

.btn.active.focus {
  outline: none;
  border: 0;
}

.btn.active:focus {
  outline: none;
  border: 0;
}

.btn.focus {
  outline: none;
  border: 0;
}

.accordion .btn-link {
  font-weight: 600;
  color: #1B3751;
  text-decoration: auto;
  width: 100%;
  text-align: left;
  font-size: 14px;
}
.accordion .btn-link:hover {
  color: #1B3751;
}
.accordion .card {
  border: 0px;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  margin-bottom: 2px;
  border-radius: 0px;
}
.accordion .card-header {
  background-color: #ffffff;
  padding: 20px 0px;
  border-bottom: 0px solid transparent;
}
.accordion .card-body {
  padding: 0px 0 20px;
}

.btn {
  transition: none;
}
.btn:active.focus {
  outline: none;
  border: 0;
}
.btn:active:focus {
  outline: none;
  border: 0;
}
.btn:focus {
  outline: none;
  border: 0;
  box-shadow: none;
}

* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  outline: none;
}

img {
  max-width: 100%;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.footer_bottom {
  min-height: 100%;
  margin-bottom: -120px;
}

.footer {
  min-height: 120px;
}

.push {
  min-height: 120px;
}

.loginbg {
  background: url(../img/loginbg.png) no-repeat center;
  background-size: cover;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.no-opacity {
  opacity: 0 !important;
}

.tooltip-inner {
  display: flex;
  background-color: #275fc6;
  margin-left: 15px;
  font-size: 12px;
}

.custom_pop .modal-content {
  border-radius: 0;
  border: 0;
}
.custom_pop .modal-dialog {
  width: 565px;
}
.custom_pop .close {
  position: absolute;
  right: -25px;
  top: 0;
  opacity: 1;
  background: transparent;
  box-shadow: none;
  border: none;
}

.login_body {
  padding: 60px;
}

.semibold_pop {
  font-size: 18px;
  color: #1b3751;
  font-weight: 700;
}

.leftmenu_data {
  position: fixed;
  left: 0;
  top: 0;
  width: 300px;
  background: #124094;
  height: 100%;
  z-index: 99;
  box-shadow: 0px 0px 12px #414141;
}

.menu_close {
  float: right;
  margin: 10px 10px 0 0;
}

.left_link {
  list-style: none;
  margin: 30px 0 0;
  padding: 0;
  width: 100%;
}
.left_link li a {
  color: #ffffff;
  font-size: 16px;
  padding: 15px 20px;
  border-bottom: 1px solid #1f55a8;
  display: block;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: none;
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #ffff;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: #275fc6;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #ffff;
  background-color: #275fc6;
}

.dropdown-menu.show {
  display: block;
  background-color: #275fc6;
}

.flp .login_filed {
  position: relative;
}
.flp input {
  width: 100%;
  display: block;
  height: 42px;
  border: 0;
  border-bottom: 2px solid #e7f3fb;
  padding: 10px 0px;
  outline: none;
  font-size: 14px;
  color: #3e4754;
  font-weight: 600;
}
.flp input:focus {
  outline: none;
}
.flp input:focus ~ .bar {
  width: 100%;
}
.flp input::placeholder {
  color: transparent;
}
.flp label {
  width: 100%;
  display: block;
  height: 42px;
  border: 0;
  border-bottom: 2px solid #e7f3fb;
  position: absolute;
  left: 0;
  top: 0;
  padding: 10px 0;
  border-color: transparent;
  cursor: text;
  font-weight: 600;
  color: #7b99b3;
  transition: top 0.2s;
  text-align: left;
}
.flp .bar {
  height: 1px;
  width: 0;
  background: #124094;
  position: absolute;
  left: 0;
  bottom: 0px;
  display: inline-block;
  -webkit-transition: 0.4s ease;
  -moz-transition: 0.4s ease;
  -ms-transition: 0.4s ease;
  -o-transition: 0.4s ease;
  transition: 0.4s ease;
}
.flp label.focussed ~ .bar {
  width: 100%;
}
.flp input[disabled] {
  background: inherit;
  border-bottom-color: #124094;
}

.ch {
  display: block;
  float: left;
  position: relative;
  background: white;
}
.ch:first-child {
  padding-left: 2px;
}
.ch:last-child {
  padding-right: 2px;
}

.focussed {
  pointer-events: none;
}

.error_msg {
  margin-bottom: 40px;
}

.txt_link {
  color: #0082fb;
  font-weight: 600;
  color: #0082fb;
  font-size: 12px;
  font-weight: 600;
  display: inline-block;
  margin-left: 5px;
}

.forgot_pass {
  font-size: 13px;
  margin-bottom: 30px;
}

.btn_login {
  background: #124094;
  font-size: 14px;
  color: #ffffff;
  border-radius: 0;
  padding: 8px 10px;
  font-weight: 600;
  min-width: 180px;
  transition: 0.5s ease;
  height: 40px;
}
.btn_login:hover {
  color: #ffffff;
  box-shadow: 0px 2px 5px #9b9b9b;
}

.create_acnt {
  margin-top: 30px;
}
.create_acnt a {
  font-size: 13px;
  margin-top: 8px;
  display: inline-block;
}

.help {
  font-weight: 600;
}

.back {
  margin: 25px 0 0;
}

.blue_box {
  background: url("../img/bg_image.png") no-repeat #1651bc;
  color: #ffffff;
  background-size: 100% 100%;
}
.blue_box .container-fluid {
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 50px;
}

.container-fluid {
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 10px;
}

.header {
  padding: 20px 0 0 0;
}

.line {
  border-top: 1px solid #fff !important;
  opacity: 0.1 !important;
}

.mobile-only {
  display: none;
}

h2 {
  word-break: break-all;
  font-family: "Playfair Display", serif !important;
}

.menu_icon {
  padding: 0;
  border-radius: 0;
  background: transparent;
  border: 0;
  margin-right: 40px;
}

.top_right {
  float: right;
  margin: 0;
  padding: 0;
  list-style: none;
}
.top_right li {
  float: right;
  padding-right: 30px;
}
.top_right li:first-child {
  padding-right: 0;
}

.user_name {
  border-radius: 100%;
  height: 40px;
  width: 40px;
  text-align: center;
  padding: 12px;
  background: #275fc6;
  color: #54abf6;
  font-weight: 700;
}

.currency_drop select {
  border-radius: 0;
  border: 0;
  background: #0e4da9 url(../img/down_arrow.svg) no-repeat 95px !important;
  font-weight: 700;
  color: #ffffff !important;
  min-width: 120px;
  height: 40px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  font-size: 14px;
}
.currency_drop select:focus {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08), 0 0 8px rgba(102, 175, 233, 0.6);
}

.mob_drop {
  display: none;
}

.top_link {
  padding-top: 10px;
}
.top_link a {
  padding-bottom: 5px;
  font-weight: 600;
  color: #ffffff;
}
.top_link a.hover:hover {
  border-bottom: 2px solid #54abf6;
}

.bell {
  padding-top: 8px;
}

.line {
  border-top: 1px solid #1f55a8;
  clear: both;
}

h1 {
  margin: 0 0 25px;
  padding: 0;
  font-family: "Playfair Display", serif !important;
  font-size: 42px;
  color: #ffffff;
  line-height: 32px;
  font-weight: 700;
}
h1 span {
  font-size: 18px;
}

.investment_box {
  background: #0f3b8b;
  padding: 10px 40px 15px;
}

.box4 {
  padding: 15px 0;
}

.box4_txt {
  position: relative;
  padding-left: 50px;
}
.box4_txt img {
  position: absolute;
  top: 7px;
  left: 0;
}

.error_message {
  text-align: center;
}

h2 {
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 24px;
  color: #ffffff;
  line-height: 20px;
  font-weight: 700;
}
h2 span {
  font-size: 12px;
  color: #54abf6;
  font-weight: 600;
}

.title_pie {
  font-size: 18px;
  font-weight: 700;
  padding: 10px 0 0 40px;
}

.download_icon {
  float: right;
  margin-left: 10px;
}

.mob_download {
  display: none;
}

.pie_legent {
  padding: 25px;
  padding-top: 65px;
}
.pie_legent h2 span {
  display: block;
}

.legent_value {
  float: left;
  width: 50%;
  position: relative;
  padding: 0 0 30px 20px;
}
.legent_value img {
  position: absolute;
  top: 8px;
  left: 0;
}

.move_up {
  margin-top: -60px;
}

.white_box {
  background: #ffffff;
  padding: 30px;
}

.fund_detail {
  margin-bottom: 25px;
}

.fund_ttl {
  font-weight: 700;
  font-size: 22px;
  display: inline-block;
  padding-top: 12px;
  margin-bottom: 10px;
}

.pt_15 {
  padding-top: 15px;
}

.custom_tab {
  float: right;
  border: 1px solid #ddecfe;
}
.custom_tab li {
  margin-bottom: 0;
  float: left;
}
.custom_tab li a {
  border: 0px;
  border-right: 1px solid #ddecfe !important;
  border-radius: 0 !important;
  margin: 0;
  font-weight: 600;
  height: 40px;
  padding: 10px 32px;
  width: 100%;
  display: inline-block;
}
.custom_tab li a:hover {
  background: #eee;
}
.custom_tab li:last-child a {
  border-right: 0 !important;
}
.custom_tab > li a.active {
  border: 0;
  background: #124094 !important;
  color: #ffffff !important;
}
.custom_tab > li a.active:focus {
  border: 0;
  background: #124094 !important;
  color: #ffffff !important;
}
.custom_tab > li a.active:hover {
  border: 0;
  background: #124094 !important;
  color: #ffffff !important;
}

.fund_row {
  padding-bottom: 30px;
}
.fund_row span {
  font-size: 12px;
  color: #7b99b3;
  font-weight: 600;
}
.fund_row label {
  font-size: 20px;
  color: #1b3751;
  font-weight: 400;
  display: block;
  line-height: 22px;
  margin-bottom: 0;
}

.label_span {
  font-size: 20px !important;
  color: #7b99b3 !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  margin-bottom: 0 !important;
}

.line_graph {
  border-top: 1px solid #e7f3fb;
  padding-top: 30px;
  margin-top: 10px;
}

.stackedbar_ttl {
  font-weight: 700;
  color: #1b3751;
  font-size: 16px;
  padding-left: 10px;
}

.filter {
  float: right;
  margin-left: 10px;
}

.upcoming_fund {
  background: url(../img/upcoming_fundbg.jpg) no-repeat;
  text-align: center;
  padding: 70px 10px;
  min-height: 360px;
  color: #ffffff;
  font-weight: 600;
  background-size: 100% 100%;
}
.upcoming_fund strong {
  color: #54abf6;
  font-weight: 700;
  font-size: 18px;
}
.upcoming_fund p {
  font-size: 24px;
  margin: 25px 0 5px;
}
.upcoming_fund span {
  font-size: 24px;
  display: block;
  line-height: 16px;
  margin-bottom: 25px;
}

h3 {
  margin: 0;
  padding: 0;
  font-family: "Playfair Display", serif !important;
  font-size: 60px;
  color: #ffffff;
  font-weight: 700;
}

.btn_view {
  background: #ffffff;
  padding: 12px 35px;
  color: #095194;
  font-weight: 700;
  display: inline-block;
}

.pop_ttl {
  font-size: 18px;
  color: #1b3751;
  font-weight: 700;
  margin-bottom: 20px;
}

.form_box label {
  font-size: 12px;
  color: #7b99b3;
  font-weight: 600;
  margin-bottom: 0;
}
.form_box .custom_tab {
  width: 100%;
  margin-top: 10px;
  float: none;
}
.form_box .custom_tab li {
  width: 33.33%;
  text-align: center;
}

.form_field {
  color: #1b3751;
  font-weight: 600;
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid #e7f3fb;
  outline: none;
  box-shadow: none;
  padding: 6px 0px;
}
.form_field:focus {
  box-shadow: none;
}
.form_field::-webkit-input-placeholder {
  color: #a7b9ca;
}
.form_field::-moz-placeholder {
  color: #a7b9ca;
}
.form_field::-o-input-placeholder {
  color: #a7b9ca;
}
.form_field:-ms-input-placeholder {
  color: #a7b9ca;
}
.form_field::input-placeholder {
  color: #a7b9ca;
}

.search {
  background: url(../img/search.svg) no-repeat right;
}

.form_field.select {
  background: url(../img/dropdown_arrow.svg) no-repeat right;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
}

.calendar {
  background: url(../img/calendar.svg) no-repeat right;
}

.btn_apply {
  background: #124094;
  padding: 12px 35px;
  color: #ffffff;
  font-weight: 700;
  cursor: pointer;
  display: inline-block;
}
.btn_apply:hover {
  color: #ffffff;
}

#StackedBar1 {
  height: 200px;
}

#StackedBar2 {
  height: 100px;
}

#StackedBar3 {
  height: 100px;
}

#StackedBar4 {
  height: 100px;
}

.timeline {
  padding: 50px 0 30px;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.timeline_data {
  list-style: none;
  padding: 0;
  margin: 0;
  border-top: 2px dotted #c4dcfa;
  display: inline-flex;
}
.timeline_data li {
  position: relative;
  float: left;
}
.timeline_data li::before {
  content: "";
  background: #a239a0;
  height: 8px;
  width: 8px;
  display: inline-block;
  position: absolute;
  top: -5px;
  left: 0;
  border-radius: 100%;
}
.timeline_data .green_box::before {
  background: #00ca61;
}

.year {
  position: absolute;
  top: -30px;
  left: 0;
  color: #a239a0;
  font-weight: 600;
}

.fund_invst {
  margin-bottom: 25px;
}

.summary_txt {
  margin: 20px 25px 0 0;
  border: 1px solid #ddecfe;
  padding: 30px 15px;
  min-width: 160px;
  height: 215px;
  width: 160px;
}
.summary_txt label {
  color: #7b99b3;
  margin: 0;
  font-size: 12px;
  font-weight: 600;
}
.summary_txt h4 {
  height: 63px;
  width: 128px;
}

h4 {
  margin: 0;
  color: #1b3751;
  font-size: 18px;
  font-family: "Playfair Display", serif !important;
  font-weight: 700;
}
h4 span {
  color: #1b3751;
  font-weight: 400;
  font-size: 14px;
}

h5, h6 {
  font-family: "Playfair Display", serif !important;
}

.amount {
  margin: 12px 0 0;
}

.popover-body {
  padding: 5px;
  color: #fff;
  background-color: #275FC7;
  font-size: 10px;
  text-align: center;
  border-radius: 0.25rem;
}

.green_box .summary_txt {
  border: 1px solid #00ca61;
  background: #f0f7ff;
}
.green_box .year {
  color: #00ca61;
}

.timeline_label {
  text-align: center;
  padding: 20px 0 10px;
}
.timeline_label label {
  display: inline-block;
  margin: 0 20px;
}
.timeline_label label img {
  margin-right: 5px;
}

#DonutChart {
  height: 250px;
}

.inv_tbl {
  margin-bottom: 25px;
}
.inv_tbl .fund_ttl {
  font-weight: 700;
  font-size: 24px;
  padding-top: 5px;
}

.tbl_custom > thead > tr > th {
  border-bottom: 0;
  background: #f2f3f8;
  color: #7b99b3;
  font-size: 12px;
  font-weight: 600;
  padding: 15px 10px;
}
.tbl_custom > thead > tr > th:first-child {
  padding-left: 50px;
}
.tbl_custom > tbody > tr > td {
  border-top: 0;
  border-bottom: 1px solid #ddecfe;
  color: #1b3751;
  font-weight: 600;
  padding: 12px 10px;
}
.tbl_custom > tbody > tr > td:first-child {
  padding-left: 50px;
}

.pagination {
  width: fit-content;
  margin: auto;
}
.pagination label {
  font-weight: 400;
  color: #1b3751;
  padding: 0 20px;
}

.next_back {
  background: transparent;
  padding: 0;
  border: 0;
  box-shadow: none;
}

th[sortable] i:before {
  content: "";
}

th[sortable].asc i:before {
  content: "";
}

th[sortable].desc i:before {
  content: "";
}

.reports {
  margin-bottom: 25px;
}
.reports .fund_ttl {
  font-weight: 700;
  font-size: 24px;
  padding-top: 5px;
}
.reports .custom_tab li a br {
  display: none;
}

.download_data {
  background: #f2f3f8;
  padding: 30px 22px;
  border: 1px solid #f2f3f8;
}
.download_data:hover {
  border: 1px solid #000000;
}
.download_data p {
  margin: 10px 0;
  font-weight: 400;
  min-height: 40px;
}
.download_data p strong {
  font-size: 18px;
  color: #1b3751;
  min-height: 70px;
}

.btn_download {
  background: #124094;
  padding: 8px 10px;
  color: #ffffff;
  font-weight: 700;
  display: block;
  border-radius: 0;
  width: 100%;
}
.btn_download:hover {
  color: #ffffff;
}

.min_height500 {
  min-height: 500px;
}

.no_data {
  text-align: center;
  margin-top: 150px;
}
.no_data img {
  opacity: 0.2;
}
.no_data p {
  color: #596a79;
  font-weight: 400;
  margin-top: 15px;
}

.modal-header {
  background: #124094;
  padding: 10px;
}
.modal-header h4 {
  color: #ffffff;
}

.modal-footer {
  padding: 10px;
}

.btn-confirmation {
  background: #124094;
  color: #ffffff;
  font-weight: 700;
  display: inline-block;
  border-radius: 0;
  padding: 5px 15px;
}

.back_link {
  padding: 20px 0 20px 20px;
  font-size: 18px;
  font-weight: 700;
  display: none;
}
.back_link img {
  margin: -1px 10px 0 0;
}
.back_link a {
  color: #ffffff;
}

.faq_tab {
  display: none;
}

.tab-content .desk_visible {
  display: block;
  opacity: 1;
}

.faq_data {
  width: 50%;
  padding-left: 15px;
  padding-right: 15px;
  float: left;
}

.faqs {
  background: #ffffff;
  padding: 30px;
  margin-top: -60px;
}
.faqs .fund_ttl {
  margin-bottom: 10px;
}
.faqs .form_box label {
  margin-bottom: 10px;
}

.faq_collapse {
  margin-bottom: 0;
}
.faq_collapse .panel-heading {
  padding: 20px 0;
}
.faq_collapse .panel-heading a {
  color: #1b3751;
  font-weight: 600;
  position: relative;
  display: block;
}
.faq_collapse .panel-heading a::after {
  content: "";
  position: absolute;
  right: 0;
  top: 4px;
  display: inline-block;
  background: url(../img/dropdown_arrow.svg) no-repeat center;
  height: 8px;
  width: 14px;
  transform: rotate(180deg);
}
.faq_collapse .panel-heading a.collapsed::after {
  transform: rotate(0);
}
.faq_collapse .panel {
  border-radius: 0;
  -webkit-box-shadow: 0 1px 1px #e5ebf0;
  box-shadow: 0 1px 1px #e5ebf0;
}
.faq_collapse .panel-body {
  padding: 0px 0 20px;
  border-top: 0 !important;
}

.basic_info label {
  font-size: 12px;
  color: #7b99b3;
  font-weight: 600;
  margin-bottom: 0;
  display: block;
}

.border {
  border-right: 1px solid #e5ebf0;
  border-left: 1px solid #e5ebf0;
  border-top: none !important;
  border-bottom: none !important;
}

.form_textarea {
  color: #1b3751;
  font-weight: 400;
  border-radius: 0;
  border: 1px solid #e7f3fb;
  outline: none;
  box-shadow: none;
  padding: 12px;
  resize: none;
}

.btn_submit {
  background: #124094;
  padding: 10px 35px;
  color: #ffffff;
  font-weight: 700;
  display: inline-block;
  border-radius: 0;
  min-width: 200px;
  opacity: 0.5;
}
.btn_submit:hover {
  color: #ffffff;
}

.active_btn {
  opacity: 1;
}

.radio_btn input[type=radio] {
  display: none;
}
.radio_btn input[type=radio] + label:before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-right: 8px;
  border-radius: 4px;
  cursor: pointer;
}
.radio_btn input[type=radio]:checked + label:before {
  text-align: center;
  line-height: 18px;
  text-shadow: 0px 0px 3px #eee;
  text-align: center;
  line-height: 18px;
  text-shadow: 0px 0px 3px #eee;
}

input[type=checkbox] {
  display: none;
}

.checkbox_btn input[type=checkbox] + label:before {
  content: "";
  display: inline-block;
  width: 17px;
  height: 17px;
  vertical-align: middle;
  margin-right: 8px;
  border-radius: 4px;
  cursor: pointer;
}
.checkbox_btn input[type=checkbox]:checked + label:before {
  text-align: center;
  line-height: 18px;
  text-shadow: 0px 0px 3px #eee;
}
.checkbox_btn label {
  font-weight: 600;
}

.mb_1 {
  margin-bottom: 10px;
}

.mb_2 {
  margin-bottom: 20px;
}

.mb_3 {
  margin-bottom: 30px;
}

.mb_4 {
  margin-bottom: 40px;
}

.mb_5 {
  margin-bottom: 50px;
}

.m_0 {
  margin: 0;
  margin: 0;
}

.mt_1 {
  margin-top: 10px;
}

.mt_2 {
  margin-top: 20px;
}

.mt_3 {
  margin-top: 30px;
}

.mt_4 {
  margin-top: 40px;
}

.mt_5 {
  margin-top: 50px;
}

.w_50 {
  width: 50%;
}

.bb_0 {
  border-bottom: 0;
}

.p_0 {
  padding: 0;
}

.text-right {
  text-align: right;
}

.loader {
  position: fixed;
  left: 50%;
  top: 50%;
}

.overlay {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  opacity: 0.5;
  background-color: #000;
  z-index: 9999;
}

.fw_6 {
  font-weight: 600;
}

.fs_12 {
  font-size: 12px;
}