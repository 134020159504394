/*--*/
/*--*/
/*--*/
/*--*/
/*--*/
/*--*/
/*--*/
/*--*/
/*--*/
@media (min-width: 1200px) {
	.container-xl,
	.container-lg,
	.container-md,
	.container-sm,
	.container {
		max-width: 1170px;
	}
}
@media (max-width: 1199px) {
	.download_data {
		padding: 30px 13px;
		p {
			font-size: 13px;
			strong {
				font-size: 14px;
			}
		}
	}
}
@media (max-width: 991px) {
	.custom_pop {
		.modal-dialog {
			width: auto;
		}
		top: 30px;
		.close {
			top: -25px;
			right: 0;
		}
	}
	.mobile-only{
		display: block;
	}
	.desktop-only{
		display: none;
	}
	.top_right {
		li {
			padding-right: 15px;
		}
	}
	.currency_drop {
		select {
			min-width: 90px;
			background-position: 65px;
		}
	}	.investment_box {
		padding: 0px 20px 10px;
		margin-bottom: 30px;
	}
	.fund_row {
		label {
			font-size: 18px;
		}
	}
	.tbl_custom {
		> thead {
			> tr {
				> th {
					&:first-child {
						padding-left: 20px;
					}
				}
			}
		}
		> tbody {
			> tr {
				> td {
					&:first-child {
						padding-left: 20px;
					}
				}
			}
		}
	}
	.table-responsive {
		border: 0;
	}
	.faq_tab {
		display: block;
	}
	.tab-content {
		.desk_visible {
			display: none;
			opacity: 0;
		}
		.desk_visible.active {
			display: block;
			opacity: 1;
		}
	}
	.mob_whitebox {
		background: #ffffff;
		padding: 30px;
		margin-top: -60px;
		margin-bottom: 30px;
	}
	.faqs {
		padding: 0;
		margin: 0;
	}
	.faq_data {
		width: 100%;
	}
}
@media (max-width: 767px) {
	.menu_icon {
		margin-right: 15px;
	}
	.logo {
		margin-top: 8px;
		display: inline-block;
		img {
			width: 245px;
		}
	}
	.user {
		display: none;
	}
	.top_link {
		display: none;
	}
	.currency_drop {
		.form-control {
			display: none;
		}
	}
	.form-control.mob_drop {
		display: inline-block;
		min-width: 67px;
		background-position: 40px;
	}
	.top_right {
		li {
			padding-right: 12px;
		}
		li.bell {
			padding-right: 0;
		}
	}
	.mob_mr {
		margin-top: 0;
	}
	.line {
		margin-top: 0;
	}
	h1 {
		font-size: 36px;
		span {
			font-size: 16px;
		}
	}
	h2 {
		font-size: 22px;
	}
	.box4_txt {
		img {
			width: 30px;
		}
		padding-left: 40px;
	}
	.white_box {
		padding: 20px 10px;
	}
	.fund_ttl {
		padding: 0 0 15px;
	}
	.fund_detail {
		.custom_tab {
			width: 100%;
			li {
				width: 25%;
				text-align: center;
			}
		}
	}
	.custom_tab {
		li {
			a {
				font-size: 12px;
				padding: 10px;
			}
		}
	}
	.fund_row {
		padding-bottom: 20px;
		span {
			font-size: 14px;
		}
		label {
			font-size: 14px;
			font-weight: 600;
		}
	}
	#StackedBar1 {
		height: 140px;
	}
	#StackedBar2 {
		height: 140px;
	}
	#StackedBar3 {
		height: 140px;
	}
	#StackedBar4 {
		height: 140px;
	}
	.fund_invst {
		margin-bottom: 15px;
	}
	.download_data {
		img {
			float: left;
			margin-right: 10px;
		}
		p {
			display: inline-block;
			margin: 0;
		}
	}
	.btn_download {
		margin-top: 10px;
	}
	.no_data {
		margin-top: 100px;
	}
	.headermob_hide {
		display: none;
	}
	.menu_icon.back_link{
		padding: 0px;
		display: inline-block;
	}
	.back_link {
		display: block;
		padding-bottom: 0;
	}
	.ttl_hide {
		display: none;
	}
	.inv_tbl {
		.custom_tab {
			width: 100%;
			margin-top: 15px;
			li {
				width: 50%;
				text-align: center;
			}
		}
	}
	.reports {
		.custom_tab {
			width: 100%;
			margin-top: 15px;
			li {
				width: 25%;
				text-align: center;
			}
		}
	}
	.mob_whitebox {
		padding: 20px 10px;
	}
	.faq_tab {
		width: 100%;
		margin-bottom: 20px;
		li {
			width: 50%;
			text-align: center;
		}
	}
	.faqs {
		.fund_ttl {
			margin-bottom: 0;
		}
	}
	.mob_0 {
		margin-top: 0;
	}
	.border {
		border: 0;
		margin: 20px 0;
	}
	.query_ftr {
		text-align: center;
	}
}
@media (max-width: 674px) {
	#StackedBar1 {
		height: 150px;
	}
}
@media (max-width: 639px) {
	.login_body {
		padding: 35px 20px;
	}
	#StackedBar1 {
		height: 170px;
	}
	#StackedBar2 {
		height: 170px;
	}
	#StackedBar3 {
		height: 170px;
	}
	#StackedBar4 {
		height: 170px;
	}
	.fund_invst {
		.custom_tab {
			width: 100%;
			margin-top: 15px;
			li {
				width: 50%;
				text-align: center;
			}
		}
	}
	.timeline_label {
		label {
			margin: 0 10px;
		}
	}
	.reports {
		.custom_tab {
			li {
				a {
					br {
						display: block;
					}
				}
				&:first-child {
					a {
						text-align: left;
						line-height: 12px;
					}
				}
				&:nth-child(2) {
					a {
						text-align: left;
						line-height: 12px;
					}
				}
			}
		}
	}
}

@media (max-width: 574px) {
	.fund_row{
		label {
			float: right;
		}
	}
}

@media (min-width: 576px) {
	.modal-dialog {
		max-width: 565px;
	}
}
@media (max-width: 489px) {
	.upcoming_fund {
		padding-top: 40px;
	}
}
@media (max-width: 400px) {
	.form-control.mob_drop {
		min-width: 50px;
		background-position: 30px;
	}
	.investment_box {
		padding: 0px 20px 15px;
	}
	.box4 {
		width: 100%;
	}
	.title_pie {
		padding-left: 0;
	}
	.pie_chart {
		padding-left: 0;
	}
	.legent_value {
		width: 100%;
		padding-bottom: 15px;
		h2 {
			text-align: right;
			span {
				display: inline-block;
				float: left;
			}
		}
	}
	#DonutChart {
		height: 250px;
		width: auto
	}
	.pie_legent {
		padding-top: 30px;
	}
	.blue_box {
		padding-bottom: 70px;
	}
	#StackedBar1 {
		height: 270px;
	}
	#StackedBar2 {
		height: 270px;
	}
	#StackedBar3 {
		height: 270px;
	}
	#StackedBar4 {
		height: 270px;
	}
	.back_link {
		padding-bottom: 20px;
	}
}
